import React, { useEffect, useState } from "react";
import "./ApiDocument.scss";
import { GoTrash } from "react-icons/go";
import { ThreeDots } from "react-loader-spinner";
import BasicTooltip from "../../../Components/Tooltip/Tooltip";
import {
  CREATE_API_KEY,
  DELETE_API_KEY,
  GET_API_KEY,
} from "../../../utils/apiPath";
import { deleteApi, getApi, postApi } from "../../../utils/apiService";
import { errorToast, successToast } from "../../../services/ToastHelper";
import CustomLayout from "../../../Components/CustomLayout/CustomLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Confirm from "../../../Components/confirmModal/confirm";
import { BackArrow } from "../../../Assets/icons";

const ApiDocument = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipTitleCurl, setTooltipTitleCurl] = useState("Copy Curl Code");
  const [tooltipTitleSuccess, setTooltipTitleSuccess] =
    useState("Copy Response");
  const [tooltipTitleBadRequest, setTooltipTitleBadRequest] =
    useState("Copy Response");
  const [tooltipTitleUnauthorized, setTooltipTitleUnauthorized] =
    useState("Copy Response");
  const [tooltipTitleInvalid, setTooltipTitleInvalid] =
    useState("Copy Response");

  const handleCopyCurlCode = () => {
    const curlCode = `curl -X 'POST' \
    'https://multi-api.myaisquad.com/api/v1/textChat/APITextChat' \
    -H 'accept: application/json' \
    -H 'Authorization: Bearer your_api_key' \
    -H 'Content-Type: application/json' \
    -d '{
    "question": "Hello"
  }'`;
    navigator.clipboard
      .writeText(curlCode)
      .then(() => {
        console.log("Curl code copied to clipboard");
        setTooltipTitleCurl("Copied!");
        setTimeout(() => {
          setTooltipTitleCurl("Copy Curl Code");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy curl code: ", error);
      });
  };

  const handleCopyResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleSuccess("Copied!");
        setTimeout(() => {
          setTooltipTitleSuccess("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  const handleCopyBadRequestResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleBadRequest("Copied!");
        setTimeout(() => {
          setTooltipTitleBadRequest("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  const handleCopyUnauthorizedResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleUnauthorized("Copied!");
        setTimeout(() => {
          setTooltipTitleUnauthorized("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  const handleCopyInvalidResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleInvalid("Copied!");
        setTimeout(() => {
          setTooltipTitleInvalid("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <CustomLayout>
      <div className="doc">
        {isLoading && (
          <div className="loader-container">
            <div className="loader">
              <ThreeDots color="#D1D1D1" height={80} width={80} radius="9" />
            </div>
          </div>
        )}
        <div className="doc_cont">
          <div className="doc_sec">
            <div className="doc_sec_header">
              <div className="doc_sec_header_back" onClick={handleBack}>
                <BackArrow />
              </div>
              <h3>API Key Document Text Chat</h3>
            </div>
            <div className="doc_sec_container">
              <div className="doc_sec_container_document">
                <div className="doc_sec_container_document_title">
                  <h3>Easy Guide to Using the Chatbot API</h3>
                  <p>
                    Welcome! This guide will help you use our Chatbot API, which
                    allows you to ask questions and receive responses based on
                    your trained data. Let's get started!
                  </p>
                </div>
                <div className="doc_sec_container_document_points">
                  <h4>What is the Chatbot API?</h4>
                  <p>
                    Our Chatbot API lets you interact with a chatbot that
                    responds using the data you have trained it with. You can
                    ask questions and get intelligent, contextual replies.
                  </p>
                </div>
                <div className="doc_sec_container_document_points">
                  <h4>Getting Your API Key.</h4>
                  <p>
                    API Key: You need an API Key to access the Chatbot API. It's
                    a unique code that proves you're allowed to use the service.
                  </p>
                  <p>
                    Keep Your API Key Safe: It's your unique access code and
                    should not be shared.
                  </p>
                </div>
                <div className="doc_sec_container_document_points">
                  <h4>How to Make a Request?</h4>
                  <p>
                    To interact with the chatbot, send a request to our API.
                    Here's an example using cURL:
                  </p>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h3>API Curl</h3>
                  <ul>
                    <li>
                      Replace your_api_key with the API key that you have
                      created.
                    </li>
                    <li>
                      In the request body, replace question with your question.
                    </li>
                    <li>
                      You can use the following curl command to make the API
                      request:
                    </li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Curl</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={handleCopyCurlCode}
                        title={tooltipTitleCurl}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>curl -X 'POST' \</code>
                      <br />
                      <code>
                        'https://multi-api.myaisquad.com/api/v1/textChat/APITextChat'
                        \
                      </code>
                      <br />
                      <code>-H 'accept: application/json' \</code>
                      <br />
                      <code>-H 'Authorization: Bearer your_api_key' \</code>
                      <br />
                      <code>-H 'Content-Type: application/json' \</code>
                      <br />
                      <code>-d {`'{`}</code>
                      <code>"question": "Your question here"</code>
                      <code>{`}'`}</code>
                      <br />
                    </pre>
                  </div>
                </div>
                <div className="doc_sec_container_content_explanation">
                  <div className="doc_sec_container_content_explanation_heading">
                    <h3>Explanation:</h3>
                  </div>
                  <div className="doc_sec_container_content_explanation_details">
                    <div className="doc_sec_container_content_explanation_details_points">
                      <h2>URL:</h2>
                      <code className="code1">
                        https://multi-api.myaisquad.com/api/v1/textChat/APITextChat
                      </code>
                      <li>This is the endpoint where you send your request.</li>
                    </div>
                    <div className="doc_sec_container_content_explanation_details_points">
                      <h2>Headers:</h2>
                      <li>
                        <code>-H 'accept: application/json'</code>: Indicates
                        that you want the response in JSON format.
                      </li>
                      <li>
                        <code>-H 'Authorization: Bearer your_api_key'</code>:
                        Include your API Key here. Replace{" "}
                        <code>your_api_key</code> with the actual key provided
                        to you.
                      </li>
                      <li>
                        <code>-H 'Content-Type: application/json'</code>:
                        Specifies that you're sending data in JSON format.
                      </li>
                    </div>

                    <div className="doc_sec_container_content_explanation_details_points">
                      <h2>Data:</h2>
                      <li>
                        <code>-d '{` "question": "Hello" `}'</code>
                      </li>
                      <li>
                        This is the body of your request, containing the
                        question you're asking the chatbot. In this case, the
                        question is <code>"Hello"</code>.
                      </li>
                    </div>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h3>API Responses</h3>
                  <h4>Successful Response</h4>
                  <ul>
                    <li>Sattus Code: '200 OK'</li>
                    <li>Description: The request was successful.</li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyResponse(
                            `{
  "status": 200,
  "message": "Success",
  "data": {
    "question": "Hello",
    "result": "Hello! How can I assist you today?"
  }
}`
                          )
                        }
                        title={tooltipTitleSuccess}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 200,
  "message": "Success",
  "data": {
    "question": "Hello",
    "result": "Hello! How can I assist you today?"
  }
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h4>Bad Request</h4>
                  <ul>
                    <li>Status Code: 400 Bad Request</li>
                    <li>
                      Description: The request was malformed or missing required
                      parameters.
                    </li>
                    <li>
                      Condition: If the question is not provided in the body.
                    </li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyBadRequestResponse(
                            `{
  "status": 400,
  "message": "Bad Request",
  "data": null
}`
                          )
                        }
                        title={tooltipTitleBadRequest}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 400,
  "message": "Bad Request",
  "data": null
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h4>Unauthorized Responses</h4>
                  <ul>
                    <li>Status Code: 401 Unauthorized</li>
                    <li>
                      Description: The request lacked proper authentication.
                    </li>
                    <li>Condition: If the API key is missing or malformed.</li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyUnauthorizedResponse(
                            `{
  "status": 401,
  "message": "Unauthorized: API key missing or malformed",
  "data": null
}`
                          )
                        }
                        title={tooltipTitleUnauthorized}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 401,
  "message": "Unauthorized: API key missing or malformed",
  "data": null
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <ul>
                    <li>Status Code: 401 Unauthorized</li>
                    <li>
                      Description: The request lacked proper authentication.
                    </li>
                    <li>Condition: If the API key is invalid.</li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyInvalidResponse(
                            `{
  "status": 401,
  "message": "Unauthorized: Invalid API key",
  "data": null
}`
                          )
                        }
                        title={tooltipTitleInvalid}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 401,
  "message": "Unauthorized: Invalid API key",
  "data": null
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <h2>Any Help</h2>
                <p>
                  If you have any questions or encounter issues, please contact
                  our support team for assistance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomLayout>
  );
};

export default ApiDocument;
