import React, { useEffect, useState } from "react";
import "./ApiDocument3.scss";
import { GoTrash } from "react-icons/go";
import { ThreeDots } from "react-loader-spinner";
import BasicTooltip from "../../../Components/Tooltip/Tooltip";
import {
  CREATE_API_KEY,
  DELETE_API_KEY,
  GET_API_KEY,
} from "../../../utils/apiPath";
import { deleteApi, getApi, postApi } from "../../../utils/apiService";
import { errorToast, successToast } from "../../../services/ToastHelper";
import CustomLayout from "../../../Components/CustomLayout/CustomLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Confirm from "../../../Components/confirmModal/confirm";
import { BackArrow } from "../../../Assets/icons";

const ApiDocument3 = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipTitleCurl, setTooltipTitleCurl] = useState("Copy Curl Code");
  const [tooltipTitleSuccess, setTooltipTitleSuccess] =
    useState("Copy Response");
  const [tooltipTitleBadRequest, setTooltipTitleBadRequest] =
    useState("Copy Response");
  const [tooltipTitleUnauthorized, setTooltipTitleUnauthorized] =
    useState("Copy Response");
  const [tooltipTitleInvalid, setTooltipTitleInvalid] =
    useState("Copy Response");

  const handleCopyCurlCode = () => {
    const curlCode = `curl -X 'GET' \
    'https://multi-api.myaisquad.com/api/v1/textChat/getUserConversationHistory?userId=user123&page=1&limit=10' \
    -H 'accept: application/json' \
    -H 'Authorization: Bearer your_api_key' \
    -H 'Content-Type: application/json' \ `;

    navigator.clipboard
      .writeText(curlCode)
      .then(() => {
        console.log("Curl code copied to clipboard");
        setTooltipTitleCurl("Copied!");
        setTimeout(() => {
          setTooltipTitleCurl("Copy Curl Code");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy curl code: ", error);
      });
  };

  const handleCopyResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleSuccess("Copied!");
        setTimeout(() => {
          setTooltipTitleSuccess("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  const handleCopyBadRequestResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleBadRequest("Copied!");
        setTimeout(() => {
          setTooltipTitleBadRequest("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  const handleCopyUnauthorizedResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleUnauthorized("Copied!");
        setTimeout(() => {
          setTooltipTitleUnauthorized("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  const handleCopyInvalidResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleInvalid("Copied!");
        setTimeout(() => {
          setTooltipTitleInvalid("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <CustomLayout>
      <div className="doc">
        {isLoading && (
          <div className="loader-container">
            <div className="loader">
              <ThreeDots color="#D1D1D1" height={80} width={80} radius="9" />
            </div>
          </div>
        )}
        <div className="doc_cont">
          <div className="doc_sec">
            <div className="doc_sec_header">
              <div className="doc_sec_header_back" onClick={handleBack}>
                <BackArrow />
              </div>
              <h3>API Key Document Registered User</h3>
            </div>
            <div className="doc_sec_container">
              <div className="doc_sec_container_document">
                <div className="doc_sec_container_document_title">
                  <h3>Easy Guide to Using the Chatbot API</h3>
                  <p>
                    Welcome! This guide will help you use our Chatbot API, which
                    allows you to ask questions and receive responses based on
                    your trained data. Let's get started!
                  </p>
                </div>
                <div className="doc_sec_container_document_points">
                  <h4>What is the Chatbot API?</h4>
                  <p>
                    Our Chatbot API lets you interact with a chatbot that
                    responds using the data you have trained it with. You can
                    ask questions and get intelligent, contextual replies.
                  </p>
                </div>
                <div className="doc_sec_container_document_points">
                  <h4>Getting Your API Key.</h4>
                  <p>
                    API Key: You need an API Key to access the Chatbot API. It's
                    a unique code that proves you're allowed to use the service.
                  </p>
                  <p>
                    Keep Your API Key Safe: It's your unique access code and
                    should not be shared.
                  </p>
                </div>
                <div className="doc_sec_container_document_points">
                  <h4>How to Make a Request?</h4>
                  <p>
                    To interact with the chatbot, send a request to our API.
                    Here's an example using cURL:
                  </p>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h3>API Curl</h3>
                  <ul>
                    <li>
                      Replace your_api_key with the API key that you have
                      created.
                    </li>
                    <li>
                      In the request body, replace question with your question.
                    </li>
                    <li>
                      You can use the following cURL command to make the POST
                      request for fetching the conversation history:
                    </li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Curl</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={handleCopyCurlCode}
                        title={tooltipTitleCurl}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>curl -X 'POST' \</code>
                      <br />
                      <code>
                        'https://multi-api.myaisquad.com/api/v1/textChat/APITextChatForUser'
                        \
                      </code>
                      <br />
                      <code>-H 'accept: application/json' \</code>
                      <br />
                      <code>-H 'Authorization: Bearer your_api_key' \</code>
                      <br />
                      <code>-d {`'{`}</code>
                      <br />
                      <code>"userId": "user123",</code>
                      <br />
                      <code>"isRegister": true,</code>
                      <br />
                      <code>"question": "What is AI?"</code>
                      <br />
                      <code>{`}'`}</code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h2>Headers:</h2>
                  <ul>
                    <li>
                      -H 'accept: application/json': Indicates that you want the
                      response in JSON format.
                    </li>
                    <li>
                      -H 'Authorization: Bearer your_api_key': Include your API
                      Key here. Replace your_api_key with the actual key
                      provided to you.
                    </li>
                    <li>
                      -H 'Content-Type: application/json': Specifies that you're
                      sending data in JSON format.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Data:</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={handleCopyCurlCode}
                        title={tooltipTitleCurl}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>-d {`'{`}</code>
                      <br />
                      <code>"userId": "user123",</code>
                      <br />
                      <code>"isRegister": true,</code>
                      <br />
                      <code>"question": "What is AI?"</code>
                      <br />
                      <code>{`}'`}</code>
                      <br />
                    </pre>
                  </div>
                </div>
              </div>
              <div>
                <span>
                  This is the body of your request, containing the user ID,
                  registration status, and the question you want to ask.
                </span>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h4 style={{ margin: "10px 0" }}>
                    Register a New User and Send a Question
                  </h4>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Request:</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={handleCopyCurlCode}
                        title={tooltipTitleCurl}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>curl -X 'POST' \</code>
                      <br />
                      <code>
                        'https://multi-api.myaisquad.com/api/v1/textChat/APITextChatForUser'
                        \
                      </code>
                      <br />
                      <code>-H 'accept: application/json' \</code>
                      <br />
                      <code>-H 'Authorization: Bearer your_api_key' \</code>
                      <br />
                      <code>-H 'Content-Type: application/json' \</code>
                      <br />
                      <code>-d {`'{`}</code>
                      <br />
                      <code>"isRegister": false,</code>
                      <br />
                      <code>"question": "What is AI?",</code>
                      <br />
                      <code>"userName": "Jane Doe"</code>
                      <br />
                      <code>"userEmail": "jane.doe@example.com"</code>
                      <br />
                      <code>"password": "securePassword123"</code>
                      <br />
                      <code>{`}'`}</code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <h2>Notes:</h2>
                <p>
                  If isRegister is set to false, the system will check if the
                  user email already exists. If it does, it returns a 400 Bad
                  Request. If the user is successfully registered, the userId
                  will be stored in the database for future queries. The userId
                  should be used for all subsequent queries to avoid
                  re-registration.
                </p>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h3>API Responses</h3>
                  <h4>1. Successful Query for Registered User</h4>
                  <ul>
                    <li>Sattus Code: '200 OK'</li>
                    <li>
                      Description: The request was successful, and the response
                      includes the answer from the AI assistant.
                    </li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyResponse(
                            `{
  "status": 200,
  "message": "Success",
 "data": {
    "question": "Hello",
    "result": "Hello! How can I assist you today?"
  }
}`
                          )
                        }
                        title={tooltipTitleSuccess}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 200,
  "message": "Success",
 "data": {
    "question": "Hello",
    "result": "Hello! How can I assist you today?"
  }
}`}
                      </code>
                    </pre>
                  </div>
                </div>
                <div
                  className="doc_sec_container_content_left"
                  style={{ marginTop: "10px" }}
                >
                  <h4>2. Successful User Registration</h4>
                  <ul>
                    <li>Sattus Code: '200 OK'</li>
                    <li>
                      Description: The user was successfully registered, and the
                      system provides a user ID for future queries.
                    </li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyResponse(
                            `{
  "status": 200,
  "message": "Success",
 "data": {
    "userId": "user123"
    "question": "Hello",
    "result": "Hello! How can I assist you today?"
  }
}`
                          )
                        }
                        title={tooltipTitleSuccess}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 200,
  "message": "Success",
 "data": {
    "userId": "user123"
    "question": "Hello",
    "result": "Hello! How can I assist you today?"
  }
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              {/* <div className="doc_sec_container_content_explanation">
                <div className="doc_sec_container_content_explanation_details">
                  <div className="doc_sec_container_content_explanation_details_points"></div>
                  <div className="doc_sec_container_content_explanation_details_points">
                    <h2>Explanation:</h2>
                    <li>
                      status: The HTTP status code indicating the success of the
                      request.
                    </li>
                    <li>
                      message: A brief message about the success of the request.
                    </li>
                    <li>data: An object containing the following fields:</li>
                    <li>
                      conversations: An array of conversation objects, each
                      containing details about a message (e.g., conversationId,
                      message, timestamp).
                    </li>
                    <li>
                      totalPages: The total number of pages available for the
                      conversation history.
                    </li>
                    <li>currentPage: The current page number for the query.</li>
                  </div>
                </div>
              </div> */}
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h4>3. Bad Request</h4>
                  <ul>
                    <li>Status Code: 400 Bad Request</li>
                    <li>
                      Description: The request was malformed or missing required{" "}
                    </li>
                    <li>parameters (e.g., question not provided).</li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyBadRequestResponse(
                            `{
  "status": 400,
  "message": "Bad Request",
  "data": null
}`
                          )
                        }
                        title={tooltipTitleBadRequest}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 400,
  "message": "Bad Request",
  "data": null
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h4>4. Unauthorized</h4>
                  <ul>
                    <li>Status Code: 401 Unauthorized</li>
                    <li>
                      Description: The request lacked proper authentication, or
                      the API key was invalid.
                    </li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyUnauthorizedResponse(
                            `{
  "status": 401,
  "message": "Unauthorized",
  "data": null
}`
                          )
                        }
                        title={tooltipTitleUnauthorized}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 401,
  "message": "Unauthorized:",
  "data": null
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h4>5. Internal Server Error</h4>
                  <ul>
                    <li>Status Code: 500 Internal Server Error</li>
                    <li>
                      Description: The server encountered an error while
                      processing the request.
                    </li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyInvalidResponse(
                            `{
  "status": 500,
  "message": "Internal Server Error",
  "data": null
}`
                          )
                        }
                        title={tooltipTitleInvalid}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 500,
  "message": "Internal Server Error",
  "data": null
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <h2>Any Help</h2>
                <p>
                  If you have any questions or encounter issues, please contact
                  our support team for assistance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomLayout>
  );
};

export default ApiDocument3;
