import React, { useEffect, useState } from "react";
import "./ApiDocument2.scss";
import { GoTrash } from "react-icons/go";
import { ThreeDots } from "react-loader-spinner";
import BasicTooltip from "../../../Components/Tooltip/Tooltip";
import {
  CREATE_API_KEY,
  DELETE_API_KEY,
  GET_API_KEY,
} from "../../../utils/apiPath";
import { deleteApi, getApi, postApi } from "../../../utils/apiService";
import { errorToast, successToast } from "../../../services/ToastHelper";
import CustomLayout from "../../../Components/CustomLayout/CustomLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Confirm from "../../../Components/confirmModal/confirm";
import { BackArrow } from "../../../Assets/icons";

const ApiDocument2 = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipTitleCurl, setTooltipTitleCurl] = useState("Copy Curl Code");
  const [tooltipTitleSuccess, setTooltipTitleSuccess] =
    useState("Copy Response");
  const [tooltipTitleBadRequest, setTooltipTitleBadRequest] =
    useState("Copy Response");
  const [tooltipTitleUnauthorized, setTooltipTitleUnauthorized] =
    useState("Copy Response");
  const [tooltipTitleInvalid, setTooltipTitleInvalid] =
    useState("Copy Response");

  const handleCopyCurlCode = () => {
    const curlCode = `curl -X 'GET' \
    'https://multi-api.myaisquad.com/api/v1/textChat/APITextChatForUser' \
    -H 'accept: application/json' \
    -H 'Authorization: Bearer your_api_key' \
    -H 'Content-Type: application/json' \ 
    -d '{
"userId": "user123",
"isRegister": true,
"question": "What is AI?"
}'
    `;

    navigator.clipboard
      .writeText(curlCode)
      .then(() => {
        console.log("Curl code copied to clipboard");
        setTooltipTitleCurl("Copied!");
        setTimeout(() => {
          setTooltipTitleCurl("Copy Curl Code");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy curl code: ", error);
      });
  };

  const handleCopyResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleSuccess("Copied!");
        setTimeout(() => {
          setTooltipTitleSuccess("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  const handleCopyBadRequestResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleBadRequest("Copied!");
        setTimeout(() => {
          setTooltipTitleBadRequest("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  const handleCopyUnauthorizedResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleUnauthorized("Copied!");
        setTimeout(() => {
          setTooltipTitleUnauthorized("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  const handleCopyInvalidResponse = (response) => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        console.log("Response copied to clipboard");
        setTooltipTitleInvalid("Copied!");
        setTimeout(() => {
          setTooltipTitleInvalid("Copy Response");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy response: ", error);
      });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <CustomLayout>
      <div className="doc">
        {isLoading && (
          <div className="loader-container">
            <div className="loader">
              <ThreeDots color="#D1D1D1" height={80} width={80} radius="9" />
            </div>
          </div>
        )}
        <div className="doc_cont">
          <div className="doc_sec">
            <div className="doc_sec_header">
              <div className="doc_sec_header_back" onClick={handleBack}>
                <BackArrow />
              </div>
              <h3>API Key Document getUserConversationHistory</h3>
            </div>
            <div className="doc_sec_container">
              <div className="doc_sec_container_document">
                <div className="doc_sec_container_document_title">
                  <h3>Easy Guide to Using the Chatbot API</h3>
                  <p>
                    Welcome! This guide will help you use our Chatbot API, which
                    allows you to ask questions and receive responses based on
                    your trained data. Let's get started!
                  </p>
                </div>
                <div className="doc_sec_container_document_points">
                  <h4>What is the Chatbot API?</h4>
                  <p>
                    Our Chatbot API lets you interact with a chatbot that
                    responds using the data you have trained it with. You can
                    ask questions and get intelligent, contextual replies.
                  </p>
                </div>
                <div className="doc_sec_container_document_points">
                  <h4>Getting Your API Key.</h4>
                  <p>
                    API Key: You need an API Key to access the Chatbot API. It's
                    a unique code that proves you're allowed to use the service.
                  </p>
                  <p>
                    Keep Your API Key Safe: It's your unique access code and
                    should not be shared.
                  </p>
                </div>
                <div className="doc_sec_container_document_points">
                  <h4>How to Make a Request?</h4>
                  <p>
                    To interact with the chatbot, send a request to our API.
                    Here's an example using cURL:
                  </p>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h3>API Curl</h3>
                  <h4>Get Conversation History for a Specific User</h4>
                  <ul>
                    <li>Request (cURL Command)</li>
                    <li>
                      You can use the following cURL command to make the GET
                      request for fetching the conversation history:
                    </li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Curl</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={handleCopyCurlCode}
                        title={tooltipTitleCurl}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>curl -X 'GET' \</code>
                      <br />
                      <code>
                        'https://multi-api.myaisquad.com/api/v1/textChat/getUserConversationHistory?userId=user123&page=1&limit=10'
                        \
                      </code>
                      <br />
                      <code>-H 'accept: application/json' \</code>
                      <br />
                      <code>-H 'Authorization: Bearer your_api_key' \</code>
                      <br />
                    </pre>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h4 style={{ marginBottom: "10px" }}>
                    This is the endpoint to retrieve the conversation history
                    for a specific user. You can pass the userId, page, and
                    limit as query parameters.
                  </h4>
                  <h4>Example Query String:</h4>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Curl</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={handleCopyCurlCode}
                        title={tooltipTitleCurl}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>?userId=user123&page=1&limit=10</code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h3>API Responses</h3>
                  <h4>Successful Response (200 OK)</h4>
                  <ul>
                    <li>
                      If the request is successful, the API will return the
                      conversation history for the user.
                    </li>
                    <li>Sattus Code: '200 OK'</li>
                    <li>Description: The request was successful.</li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyResponse(
                            `{
  "status": 200,
  "message": "Success",
 "data": {
    "conversations": [
      {
        "conversationId": "conv123",
        "message": "Hello, how can I assist you?",
        "timestamp": "2024-12-01T10:00:00Z"
      },
      {
        "conversationId": "conv124",
        "message": "What is AI?",
        "timestamp": "2024-12-01T10:05:00Z"
      }
    ],
    "totalPages": 5,
    "currentPage": 1
  }
}`
                          )
                        }
                        title={tooltipTitleSuccess}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 200,
  "message": "Success",
  "data": {
    "conversations": [
      {
        "conversationId": "conv123",
        "message": "Hello, how can I assist you?",
        "timestamp": "2024-12-01T10:00:00Z"
      },
      {
        "conversationId": "conv124",
        "message": "What is AI?",
        "timestamp": "2024-12-01T10:05:00Z"
      }
    ],
    "totalPages": 5,
    "currentPage": 1
  }
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content_explanation">
                <div className="doc_sec_container_content_explanation_details">
                  <div className="doc_sec_container_content_explanation_details_points"></div>
                  <div className="doc_sec_container_content_explanation_details_points">
                    <h2>Explanation:</h2>
                    <li>
                      status: The HTTP status code indicating the success of the
                      request.
                    </li>
                    <li>
                      message: A brief message about the success of the request.
                    </li>
                    <li>data: An object containing the following fields:</li>
                    <li>
                      conversations: An array of conversation objects, each
                      containing details about a message (e.g., conversationId,
                      message, timestamp).
                    </li>
                    <li>
                      totalPages: The total number of pages available for the
                      conversation history.
                    </li>
                    <li>currentPage: The current page number for the query.</li>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h4>Error Responses:</h4>
                  <ul>
                    <li>
                      Bad Request (400): If required parameters are missing or
                      invalid, this response is returned.
                    </li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyBadRequestResponse(
                            `{
  "status": 400,
  "message": "Bad Request",
  "data": null
}`
                          )
                        }
                        title={tooltipTitleBadRequest}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 400,
  "message": "Bad Request",
  "data": null
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h4>Unauthorized Responses</h4>
                  <ul>
                    <li>
                      If the request does not include a valid API key or
                      authentication is missing/incorrect, this response is
                      returned.
                    </li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyUnauthorizedResponse(
                            `{
  "status": 401,
  "message": "Unauthorized",
  "data": null
}`
                          )
                        }
                        title={tooltipTitleUnauthorized}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 401,
  "message": "Unauthorized:",
  "data": null
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <div className="doc_sec_container_content_left">
                  <h4>Internal Server Error (500):</h4>
                  <ul>
                    <li>
                      If the server encounters an error while processing the
                      request, this response is returned.
                    </li>
                  </ul>
                </div>
                <div className="doc_sec_container_content_right">
                  <div className="doc_sec_container_content_right_header">
                    <div className="doc_sec_container_content_right_header_left">
                      <h3>Response Body:</h3>
                    </div>
                    <div className="doc_sec_container_content_right_header_right">
                      <BasicTooltip
                        className="curlicon"
                        onClick={() =>
                          handleCopyInvalidResponse(
                            `{
  "status": 500,
  "message": "Internal Server Error",
  "data": null
}`
                          )
                        }
                        title={tooltipTitleInvalid}
                      />
                    </div>
                  </div>
                  <div className="doc_sec_container_content_right_curl">
                    <pre>
                      <code>
                        {`{
  "status": 500,
  "message": "Internal Server Error",
  "data": null
}`}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="doc_sec_container_content">
                <h2>Notes:</h2>
                <p>
                  The userId must be provided as a query parameter, as it is
                  required to identify the user whose conversation history is
                  being fetched. Pagination is optional but recommended for
                  handling large sets of conversation data. You can control the
                  number of records per page (limit) and specify the page number
                  (page).
                </p>
                <p>
                  If the apiKey is invalid or missing, the request will be
                  denied with an Unauthorized response.
                </p>
              </div>
              <div className="doc_sec_container_content">
                <h2>Any Help</h2>
                <p>
                  If you have any questions or encounter issues, please contact
                  our support team for assistance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomLayout>
  );
};

export default ApiDocument2;
